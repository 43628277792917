import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(telecom: any) {
    if (telecom) {
        var value = telecom.toString().trim().replace(/^\+/, '');

        if (value.match(/[^0-9]/)) {
            return telecom;
        }

        var country, city, number;

        switch (value.length) {
            case 10: // +1PPP####### -> C (PPP) ###-####
                country = "";
                city = value.slice(0, 3);
                number = value.slice(3);
                break;

            case 11: // +CPPP####### -> CCC (PP) ###-####
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
                break;

            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 2);
                city = value.slice(2, 5);
                number = value.slice(5);
                break;

            default:
                return telecom;
        }

        // if (country == 1) {
        //     country = country;
        // }

        number = number.slice(0, 3) + '-' + number.slice(3);

        return (country + " (" + city + ") " + number).trim();
    }
}

}
