import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { LoginService } from '@core/service/Login/login.service';
import { CommonService } from '@shared/services/common.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  islogin:any
  constructor(private _loginService: LoginService, private _router: Router, private commonService: CommonService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if (
    //   this._loginService.get_session('Token') &&
    //   this._loginService.get_session('UserID')
    // ) {
    //   //if (sessionStorage.getItem('qadminuser'))
    //   this._router.navigateByUrl('/app');
    //   return true;
    // }
    // //alert('Please Login to proceed ');
    // this._loginService.logout();
    // return false;

    if(this.commonService.getSessionStorage('username')){
      return true
    }else{
      this._router.navigate(['/login']);
      return false
    }
  }

}
