import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

export enum QueueState {
  FLOATING, PINNED, EXTENDED, MOBILE, UNPINNED
};

@Injectable({
  providedIn: 'root',
})
export class QueueDisplayService {
  public isQueueVisible$ = new BehaviorSubject<boolean>(false);
  public queueState$ = new BehaviorSubject<QueueState>(QueueState.FLOATING);

  public isQueuePinned$ = new BehaviorSubject<boolean>(false);
  public isQueueExtended$ = new BehaviorSubject<boolean>(false);
  public patientList = new BehaviorSubject<any>({});
  public problemList = new BehaviorSubject<any>({});
  public orderWorklistSub = new Subject<any>();
  public exceptionstacktrace = new BehaviorSubject<any>("");
  public emrid = new BehaviorSubject<any>("");


  public changegrid = new BehaviorSubject<boolean>(false);
  public isactivitypin = new BehaviorSubject<boolean>(false);
  public isorderpin = new BehaviorSubject<boolean>(false);
  changegrid1 =  new BehaviorSubject<boolean>(false);

  constructor() { }

  public setQueueState(state: QueueState) {
    this.queueState$.next(state);
  }

  public toggleQueueVisible() {
    this.isQueueVisible$.next(!this.isQueueVisible$.value);
  }
 
  public showQueue() {
    this.isQueueVisible$.next(true);
  }
  public hideQueue() {
    this.isQueueVisible$.next(false);
  }

  // DELETE BELOW


  public toggleQueuePinned() {
    this.isQueuePinned$.next(!this.isQueuePinned$.value);
  }

  public toggleQueueExtended() {
    this.isQueueExtended$.next(!this.isQueueExtended$.value);
  }

  public pinQueue() {
    this.isQueuePinned$.next(true);
  }
  public unpinQueue() {
    this.isQueuePinned$.next(false);
  }
  public expandQueue() {
    this.isQueueExtended$.next(true);
  }
  public condenseQueue() {
    this.isQueueExtended$.next(false);
  }
  public set_emrid(id: any) {
    this.emrid.next(id);
  }
  public get_emrid() {
    return this.emrid.asObservable();
  }

}
