import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { geolocation } from '@core/Models/logModel';
import { QueueDisplayService } from '@shared/services/queue-display.service';
import { LoginService } from '../Login/login.service';
// import { WriteExceptionLogService } from './write-exception-log.service';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  postdata = new geolocation();
  Geolocation: any;
  user: any;
  constructor(
    private _loginService: LoginService,
    // private _logService: WriteExceptionLogService,
    private injector: Injector,
    private queueDisplayService: QueueDisplayService,

  ) {
    this.Geolocation = this._loginService.get_geolocation();
    this.user = this._loginService.get_User();
    // console.log('location', this.Geolocation);
    this.postdata.application = 'qprime';
    this.postdata.city = this.Geolocation ? this.Geolocation.city : '';
    this.postdata.clientip = this.Geolocation ? this.Geolocation.query : '';
    this.postdata.country = this.Geolocation ? this.Geolocation.country : '';
    this.postdata.region = this.Geolocation ? this.Geolocation.region : '';
    this.postdata.continent = this.Geolocation ? this.Geolocation.timezone : '';
    this.postdata.userfullname = this.user
      ? this.user.firstname + ' ' + this.user.lastname
      : '';
    this.postdata.userid = this.user ? this.user.userid : '';
    this.postdata.severity = 'ERROR';
  }

  handleError(error: any) {
    this.queueDisplayService.exceptionstacktrace.subscribe((error) => {
      this.postdata.exceptionstacktrace =error;
    });
    let router = this.injector.get(Router);
    this.postdata.module = router.url.split('/').slice(-1).toString();
    // let loggerService = this.injector.get(WriteExceptionLogService);
    // this._logService.writeExceptionLog(this.postdata).subscribe((res) => {
    // });
    // loggerService.log(error);
  }
}
