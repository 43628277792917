import { PatientDemographicsComponent } from './main/pages/patients/components/patient-demographics/patient-demographics.component';
import { PatientFacesheetComponent } from './main/pages/patients/subviews/patient-facesheet/patient-facesheet.component';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import firebase from 'firebase/compat/app';
import { HttpConfigInterceptor } from '@core/inceptor/httpConfig.interceptor';
import { SearchfilterPipe } from './core/Pipe/search/searchfilter.pipe';
import { AuthGuard } from '@core/Auth/auth.guard';
import { LandingComponent } from './alt-views/landing/landing.component';
import { PipeModule } from '@shared/pipe/pipe.module';
// for slider
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { GlobalErrorHandlerService } from '@core/service/Logging/GlobalErrorHandler';
import { KendoModule } from './kendo-module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { EditorModule } from '@progress/kendo-angular-editor';
import { LabelModule } from '@progress/kendo-angular-label';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { ForgotPasswordComponent } from './alt-views/forgot-password/forgot-password.component';
import { ToastrModule } from 'ngx-toastr';


const app = firebase.initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [AppComponent, LandingComponent, ForgotPasswordComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KendoModule,
    BrowserAnimationsModule,
    // PatientDemographicsComponent,
    // PatientDemographicsInfoComponent,
    IonicModule.forRoot({
      // mode: 'ios'
    }),

    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    PipeModule,
    SlickCarouselModule,
    EditorModule,
    LabelModule,
    PDFExportModule,
    ExcelExportModule,
    ToolBarModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-right',
      closeButton: true,
    }),


  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,

      useClass: HttpConfigInterceptor,
      // provider:[PatientFacesheetComponent],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },

    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
