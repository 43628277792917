import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getinitial'
})
export class GetinitialPipe implements PipeTransform {

  public transform(value: string): any {
    if (value) {
        try {
            const splitResult = value.trim().split(',');
            if (splitResult.length === 1) {
                var str = splitResult[0].trim();
                return str[0].toUpperCase() + str[1].toUpperCase()
            }
            else if (splitResult.length === 2) {
                var str1 = splitResult[0].trim();
                var str2 = splitResult[1].trim();
                return str1[0].toUpperCase() + str2[0].toUpperCase();
            }
            else if (splitResult.length === 3) {
                var str1 = splitResult[0].trim();
                var str2 = splitResult[1].trim();
                var str3 = splitResult[2].trim();
                return str1[0].toUpperCase() + str3[0].toUpperCase();
            }
            else if (splitResult.length === 4 || splitResult.length > 4) {
                var str1 = splitResult[0].trim();
                var str2 = splitResult[1].trim();
                var str3 = splitResult[2].trim();
                var str4 = splitResult[3].trim();
                return str1[0].toUpperCase() + str4[0].toUpperCase();
            }
        } catch (error) {
            return (value.length > 1) ? value[0].toUpperCase() + value[1].toUpperCase() : value[0];
        }
    }
}

}
