import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhonePipe } from './phone.pipe';
import { GetinitialPipe } from '@core/Pipe/initials/getinitial.pipe';




@NgModule({
    declarations: [
        PhonePipe,
        GetinitialPipe

    ],
    exports: [
        PhonePipe,
        GetinitialPipe
    ],
    imports: [
        CommonModule
    ]
})
export class PipeModule { }
