/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
//httpConfig.interceptor.ts
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoginService } from '@core/service/Login/login.service';
import { QueueDisplayService } from '@shared/services/queue-display.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  loaderToShow: any;
  constructor(public loadingController: LoadingController, private _loginService: LoginService,private queueDisplayService:QueueDisplayService) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this._loginService.get_session('Token');
    //Authentication by setting header with token value
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json',
        },
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });
    // this.showLoader();
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
          this.hideLoader();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.hideLoader();
// called error handler
        //  throw Error(JSON.stringify({url:error.url,status:error.status,errMessage:error.error.error}));
         throw Error('Error :-' +
         error.error +
         ', Url :- ' +
         error.url +
         ', Status Code :-' +
         error.status)
      })
    );
  }

  showLoader() {
    this.loaderToShow = this.loadingController
      .create({
        message: 'Processing Server Request',
      })
      .then((res: any) => {
        res.present();
        this.dismiss(res);
      });
    this.hideLoader();
    // this.doStopLoader();
  }
  dismiss(res: any) {
    res.onDidDismiss().then((dis: any) => {
      // this.doStopLoader();
    });
  }
  hideLoader() {
    // Uncaught (in promise): overlay does not exist isuue resoled by using getTop check loader is active before closing it
    this.loadingController.getTop().then(v => v ? this.doStopLoader() : null);
  }
  async doStopLoader() {
    await this.loadingController.dismiss();
  }
}
