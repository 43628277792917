import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { QueueDisplayService } from './queue-display.service';
import { AES, enc } from 'crypto-js';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public isAlertPinned$ = new BehaviorSubject<boolean>(false);


  constructor(private _queueDisplay:QueueDisplayService,private _router: Router) { }

  getEncryptedPatientCode(patientCode: string) {
    // ***-**-6789
    if (patientCode) {
      if (Number(patientCode.length) >= 4) {
        return '***-**-' + patientCode.substr(patientCode.length - 4);
      }
      return '***-**-****';
    }
    return '';

  }

  formatPhoneNumber(phoneNumber: string) {
    //877-752-1233
    if (phoneNumber) {
      var cleaned = ('' + phoneNumber).replace(/\D/g, '');
      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return match[1] + '-' + match[2] + '-' + match[3];
      }
    }
    return '';
  }

  calculateAge(dob: string | number | Date) {
    try {
      if (moment(dob).isValid()) {
        if (dob) {
          let totalMonths = moment().diff(dob, 'months');
          let years = totalMonths / 12;
          var months = totalMonths % 12;
          if (months !== 0) {
            return parseInt(years.toString());
          }
          return years;
        }
      } else {
        throw 'Date Exception :- invalid date format'; // generates an exception
      }
    } catch (e) {
      // statements to handle any exceptions
      console.log(e);
      this.catchException(e);
      // pass exception object to error handler
    }

    return null;
  }

  catchException(e: any) {
    this._queueDisplay.exceptionstacktrace.next(e);
    throw Error('Date Exception :-' + e);
  }

  public setSessionStorage(key: any, value: any) {
    // return sessionStorage.setItem(key, this.encryptData(value))

    if (value === "null") {
      return sessionStorage.setItem(key, (value))
    } else if (value === "") {
      return sessionStorage.setItem(key, (value))
    } else if (value === "[]") {
      return sessionStorage.setItem(key, (value))
    } else {
      return sessionStorage.setItem(key, this.encryptData(value))
    }
  }

  public getSessionStorage(key: any) {

    // if (sessionStorage.getItem(key)) {
    //   let decryptSessionValue = this.decryptData(sessionStorage.getItem(key));
    //   return (decryptSessionValue);
    // } else {
    //   return (sessionStorage.getItem(key));
    // }
    if (sessionStorage.getItem(key)) {
      var value = sessionStorage.getItem(key)
      if (value === "null") {
        return value
      } else if (value === "") {
        return value
      } else if (value === "[]") {
        return value
      } else {
        return this.decryptData(sessionStorage.getItem(key));
      }
    }
    else {
      return ""
    }
  }

  encryptData(data: any): string {
    const encryptedData = AES.encrypt((data), environment.secretKey).toString();
    return encryptedData;
  }

  decryptData(encryptedData: any): any {
    try {
      const decryptedBytes = AES.decrypt(encryptedData, environment.secretKey);
      const decryptedData = JSON.parse(decryptedBytes.toString(enc.Utf8));
      return decryptedData;
    } catch (error: any) {
      console.log("Error ", error.message)
      if (error.message.includes("Malformed UTF-8 data")) {
        // console.log("Error includes--- ",error.message)
        this.removeAllStorage()
        this._router.navigate(['login']).then(() => { window.location.reload(); });
      }
    }
  }
  removeAllStorage() {
    localStorage.clear()
    sessionStorage.clear()
  }

  toggleAlertPinned(e: any) {
    if(e == true){
      this.isAlertPinned$.next(true);
    }else{
      this.isAlertPinned$.next(false);
    }
    // this.isAlertPinned$.next(!this.isAlertPinned$.value);
  }
}

