import { Injectable } from '@angular/core';
import firebase from "firebase/compat/app";
import { environment } from 'src/environments/environment';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { CommonService } from '@shared/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
   constructor(private http: HttpClient, private handler: HttpBackend,
    private _router:Router,private _commonService:CommonService) {

  }

  doLogin(value: { email: string; password: string; }) {
    //console.log("In Do Login...")
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(value.email, value.password)
         .then(
          res => {
            // this.getIP();
            //firebase.auth().signInWithCustomToken
            var user = firebase.auth().currentUser;

            if (user?.emailVerified) {
              //console.log("EMail Verified")
              resolve(res);
            }
            else {
              firebase.auth().signOut();
              //console.log("EMail Not Verified")
            }
          },
          err => reject(err)
        );
    });
  }

  public set_geolocation(geolocation: any) {
    return localStorage.setItem("geolocation", JSON.stringify(geolocation))
  }
  public get_geolocation() {
    let data: any;
    data = localStorage.getItem('geolocation');
    return JSON.parse(data);
  }
  public set_session(key: any, value: any) {
    return localStorage.setItem(key, value)
  }
  public get_session(key: any) {
    return localStorage.getItem(key);
  }
  public remove_session(key: any) {
    return sessionStorage.removeItem(key)
  }

  public save_User(User: any) {
    return localStorage.setItem("User", JSON.stringify(User))
  }

  public get_User() {
    let data: any;
    data = localStorage.getItem('User');
    return JSON.parse(data);
  }

  logout() {
    firebase.auth().signOut().then(() => {
      this._commonService.removeAllStorage()
      this._router.navigate(['login']).then(() => { window.location.reload(); });
    });
  }

  // getQoreUserDetails(cicpuserid: any, token: any) {
  //   let httpOptions = {
  //     headers: new HttpHeaders(
  //       { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token })
  //   };
  //   return this.http.get<any>(environment.auth_url + "cicp/authorizeUserForApp/" + cicpuserid + '/4', httpOptions);
  // }

  // getIP() {
  //   this.http.get('http://ip-api.com/json').subscribe((res3: any) => {
  //     this.set_geolocation(res3);
  //   });
  // }
}
