import { Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { initializeApp } from 'firebase/app';
import { indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { environment } from 'src/environments/environment';
import {  StatusBar, Style } from '@capacitor/status-bar';
import { IonicModule } from '@ionic/angular';


const setStatusBarStyleDark = async () => {
  await StatusBar.setStyle({ style: Style.Dark });
};

const setStatusBarStyleLight = async () => {
  await StatusBar.setStyle({ style: Style.Light });
};

const hideStatusBar = async () => {
  await StatusBar.hide();
};

const showStatusBar = async () => {
  await StatusBar.show();
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'qprime';
  constructor() {
    const app = initializeApp(environment.firebaseConfig);
    if (Capacitor.isNativePlatform()) {
      initializeAuth(app, {
        persistence: indexedDBLocalPersistence
      });

      // iOS only
      window.addEventListener('statusTap', function () {
        console.log('statusbar tapped');
      });

      // Display content under transparent status bar (Android only)
      StatusBar.setOverlaysWebView({ overlay: false });

      }
  }



  ngOnInit(): void {
    this.initializeApp();
  }
  private initializeApp(): void {
    setTimeout(() => {
      SplashScreen.hide();
    }, 500);
  }




}
