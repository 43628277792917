export class geolocation {
  'message':             string;
  'exceptionstacktrace': string;
  'application':         string;
  'module':              string;
  'screen':              string;
  'loginuser':           string;
  'useraction':          string;
  'transactionid':       string;
  'clientip':            string;
  'clientbrowser':       string;
  'severity':            string;
  'outcome':             string;
  'city':                string;
  'continent':           string;
  'country':             string;
  'countrycode':         string;
  'region':              string;
  'groupid':             string;
  'organizationid':      string;
  'auditlogcode':        string;
  'userfullname':        string;
  'userid':              string;
  'tenantid':            string;
}
