import { NgModule } from '@angular/core';

import { PopupModule } from '@progress/kendo-angular-popup';
import { PopoverModule } from '@progress/kendo-angular-tooltip';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { CalendarModule, DateInputModule, DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ComboBoxModule, DropDownListModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EditorModule } from "@progress/kendo-angular-editor";
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { TooltipModule, TooltipsModule } from '@progress/kendo-angular-tooltip';
import { UploadsModule } from '@progress/kendo-angular-upload';

import { WindowModule } from "@progress/kendo-angular-dialog";

@NgModule({
    declarations: [],
    exports: [
        PopoverModule,
        PopupModule,
        GridModule,
        DatePickerModule,
        ButtonsModule,
        TooltipModule,
        TooltipsModule,
        EditorModule,
        UploadsModule,
        LayoutModule,
        DropDownsModule,
        WindowModule,
        LabelModule, InputsModule, DropDownListModule, DateInputsModule, CalendarModule, ComboBoxModule, DateInputModule, DialogsModule, SchedulerModule
    ],
    imports: [

    ]
})
export class KendoModule { }
