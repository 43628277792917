import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/Auth/auth.guard';
import { LandingComponent } from './alt-views/landing/landing.component';
import { ForgotPasswordComponent } from './alt-views/forgot-password/forgot-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'app',
    canActivate:[AuthGuard],
    loadChildren: () =>
      import('./main/main.module').then((m) => m.MainModule),

  },
  // alt views
  {
    path: 'login',
    loadChildren: () =>
      import('./alt-views/login/login.module').then((m) => m.LoginModule)

  },
  {
    path: 'landing',
    component:LandingComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: '**',
    redirectTo: 'app',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }