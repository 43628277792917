const base_pathways_service = "https://devservices.qpathways.com";

export const environment = {
    production: false,
    currentEnvironment: "Development",
    qore_url: 'https://qore-dev.myqone.com/',
    auth_url: "https://qore-dev.myqone.com/auth/",
    rx_url: "https://rxservices-dev.myqone.com/",
    host: base_pathways_service + "/otc/",
    qdrive_url:' https://qdrive-dev.myqone.com/dms/',
    EMR_MappingID :'54AB8D81-22A9-4F5F-B3F5-018D603C2A29',
    clinicid:1,
    secretKey: 'cUuv+sAjV4vzs5r+hfobOQ==',


    // firebase
    firebaseConfig: {
        apiKey: " AIzaSyAf6l7qoiXHWg1n4V0gqaWC-jXm4yvVkwA",
      //apiKey: "AIzaSyCKHy-uhhXbITqXSteKv7lAKdAVbFwpPMY",
        authDomain: "qpathways-dev.firebaseapp.com",
        databaseURL: "https://qpathways-dev.firebaseio.com",
        projectId: "qpathways-dev",
        storageBucket: "qpathways-dev.appspot.com",
        messagingSenderId: "413178660353",
        appId: "1:413178660353:web:6f8e21f6ecd9b665d4a200",
        measurementId: "G-K21LBEKQLR"
    }
};
