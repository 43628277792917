<div class="y-scroll">
    <form class="loginBox ion-justify-content-between">
        <!-- icon -->
        <ion-img class="loginBox-logo" src="assets/img/logo-color.png"></ion-img>
        <div class="loginBox-otheracc">
            <ion-text >
                <h2>Recover Your account</h2>
            </ion-text>
        </div>

        <div class="loginBox-fields mb-10">
            <ion-item class="ion-margin-top mb-20">
                <ion-label position="stacked" for="email" class="gray100">Email Address</ion-label>
                <ion-input id="login-ed" type="email" name="email" placeholder="Enter Email" class="mt-7" ngModel #email="ngModel" required
                    autocorrect="off" spellcheck="false"></ion-input>
            </ion-item>
        </div>
            <ion-button class="loginBox-button button-solid" expand="block" color="primary" >Recover</ion-button>
            <!-- <ion-button fill="solid" color="highlight" class="filled-icon-btn w-100 "  *ngIf="isopenforgotpassword">
                <ion-text class="body2-txt">Recover</ion-text>
            </ion-button> -->
        <ion-item lines="none">
            <ion-label class="subtitle ion-no-margin">
                <a href="" target="_blank">Back</a>
            </ion-label>
        </ion-item>
    </form>

    <div class="login-copyright">
        © Copyright TRIARQ Health 2022. All Rights Reserved.
    </div>

</div>
